@font-face
{
    font-family: 'boxicons';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/boxicons.eot');
    src: url('../fonts/boxicons.eot') format('embedded-opentype'),
    url('../fonts/boxicons.woff2') format('woff2'),
    url('../fonts/boxicons.woff') format('woff'),
    url('../fonts/boxicons.ttf') format('truetype'),
    url('../fonts/boxicons.svg?#boxicons') format('svg');
}
.bx
{
    font-family: 'boxicons' !important;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    display: inline-block;

    text-transform: none;

    speak-as: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.bx-ul
{
    margin-left: 2em;
    padding-left: 0;

    list-style: none;
}
.bx-ul > li
{
    position: relative;
}
.bx-ul .bx
{
    font-size: inherit;
    line-height: inherit;

    position: absolute;
    left: -2em;

    width: 2em;

    text-align: center;
}
@-webkit-keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}
@keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
    100%
    {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}
@-webkit-keyframes burst
{
    0%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
    90%
    {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);

        opacity: 0;
    }
}
@keyframes burst
{
    0%
    {
        -webkit-transform: scale(1);
                transform: scale(1);

        opacity: 1;
    }
    90%
    {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);

        opacity: 0;
    }
}
@-webkit-keyframes flashing
{
    0%
    {
        opacity: 1;
    }
    45%
    {
        opacity: 0;
    }
    90%
    {
        opacity: 1;
    }
}
@keyframes flashing
{
    0%
    {
        opacity: 1;
    }
    45%
    {
        opacity: 0;
    }
    90%
    {
        opacity: 1;
    }
}
@-webkit-keyframes fade-left
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);

        opacity: 0;
    }
}
@keyframes fade-left
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(-20px);
                transform: translateX(-20px);

        opacity: 0;
    }
}
@-webkit-keyframes fade-right
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(20px);
                transform: translateX(20px);

        opacity: 0;
    }
}
@keyframes fade-right
{
    0%
    {
        -webkit-transform: translateX(0);
                transform: translateX(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateX(20px);
                transform: translateX(20px);

        opacity: 0;
    }
}
@-webkit-keyframes fade-up
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);

        opacity: 0;
    }
}
@keyframes fade-up
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(-20px);
                transform: translateY(-20px);

        opacity: 0;
    }
}
@-webkit-keyframes fade-down
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);

        opacity: 0;
    }
}
@keyframes fade-down
{
    0%
    {
        -webkit-transform: translateY(0);
                transform: translateY(0);

        opacity: 1;
    }
    75%
    {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);

        opacity: 0;
    }
}
@-webkit-keyframes tada
{
    from
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    10%,
    20%
    {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
                transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
    }

    30%,
    50%,
    70%,
    90%
    {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
                transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }

    40%,
    60%,
    80%
    {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
                transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    }

    to
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}

@keyframes tada
{
    from
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }

    10%,
    20%
    {
        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
                transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
    }

    30%,
    50%,
    70%,
    90%
    {
        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
                transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }

    40%,
    60%,
    80%
    {
        -webkit-transform: rotate3d(0, 0, 1, -10deg);
                transform: rotate3d(0, 0, 1, -10deg);
    }

    to
    {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
    }
}
.bx-spin
{
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}
.bx-spin-hover:hover
{
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
}

.bx-tada
{
    -webkit-animation: tada 1.5s ease infinite;
            animation: tada 1.5s ease infinite;
}
.bx-tada-hover:hover
{
    -webkit-animation: tada 1.5s ease infinite;
            animation: tada 1.5s ease infinite;
}

.bx-flashing
{
    -webkit-animation: flashing 1.5s infinite linear;
            animation: flashing 1.5s infinite linear;
}
.bx-flashing-hover:hover
{
    -webkit-animation: flashing 1.5s infinite linear;
            animation: flashing 1.5s infinite linear;
}

.bx-burst
{
    -webkit-animation: burst 1.5s infinite linear;
            animation: burst 1.5s infinite linear;
}
.bx-burst-hover:hover
{
    -webkit-animation: burst 1.5s infinite linear;
            animation: burst 1.5s infinite linear;
}
.bx-fade-up
{
    -webkit-animation: fade-up 1.5s infinite linear;
            animation: fade-up 1.5s infinite linear;
}
.bx-fade-up-hover:hover
{
    -webkit-animation: fade-up 1.5s infinite linear;
            animation: fade-up 1.5s infinite linear;
}
.bx-fade-down
{
    -webkit-animation: fade-down 1.5s infinite linear;
            animation: fade-down 1.5s infinite linear;
}
.bx-fade-down-hover:hover
{
    -webkit-animation: fade-down 1.5s infinite linear;
            animation: fade-down 1.5s infinite linear;
}
.bx-fade-left
{
    -webkit-animation: fade-left 1.5s infinite linear;
            animation: fade-left 1.5s infinite linear;
}
.bx-fade-left-hover:hover
{
    -webkit-animation: fade-left 1.5s infinite linear;
            animation: fade-left 1.5s infinite linear;
}
.bx-fade-right
{
    -webkit-animation: fade-right 1.5s infinite linear;
            animation: fade-right 1.5s infinite linear;
}
.bx-fade-right-hover:hover
{
    -webkit-animation: fade-right 1.5s infinite linear;
            animation: fade-right 1.5s infinite linear;
}
.bx-xs
{
    font-size: 1rem!important;
}
.bx-sm
{
    font-size: 1.55rem!important;
}
.bx-md
{
    font-size: 2.25rem!important;
}
.bx-fw
{
    font-size: 1.2857142857em;
    line-height: .8em;

    width: 1.2857142857em;
    height: .8em;
    margin-top: -.2em!important;

    vertical-align: middle;
}

.bx-lg
{
    font-size: 3.0rem!important;
}
.bx-pull-left
{
    float: left;

    margin-right: .3em!important;
}

.bx-pull-right
{
    float: right;

    margin-left: .3em!important;
}
.bx-rotate-90
{
    transform: rotate(90deg);

    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
      filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
}
.bx-rotate-180
{
    transform: rotate(180deg);

    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
      filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
}
.bx-rotate-270
{
    transform: rotate(270deg);

    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
      filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
}
.bx-flip-horizontal
{
    transform: scaleX(-1);

    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
      filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
}
.bx-flip-vertical
{
    transform: scaleY(-1);

    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
      filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
}
.bx-border
{
    padding: .25em;

    border: .07em solid rgba(0,0,0,.1);
    border-radius: .25em;
}
.bx-border-circle
{
    padding: .25em;

    border: .07em solid rgba(0,0,0,.1);
    border-radius: 50%;
}


.bxl-500px:before {
  content: "\e900";
}
.bxl-adobe:before {
  content: "\e901";
}
.bxl-airbnb:before {
  content: "\e902";
}
.bxl-algolia:before {
  content: "\e903";
}
.bxl-amazon:before {
  content: "\e904";
}
.bxl-android:before {
  content: "\e905";
}
.bxl-angular:before {
  content: "\e906";
}
.bxl-apple:before {
  content: "\e907";
}
.bxl-audible:before {
  content: "\e908";
}
.bxl-baidu:before {
  content: "\e909";
}
.bxl-behance:before {
  content: "\e90a";
}
.bxl-bing:before {
  content: "\e90b";
}
.bxl-bitcoin:before {
  content: "\e90c";
}
.bxl-blogger:before {
  content: "\e90d";
}
.bxl-bootstrap:before {
  content: "\e90e";
}
.bxl-chrome:before {
  content: "\e90f";
}
.bxl-codepen:before {
  content: "\e910";
}
.bxl-creative-commons:before {
  content: "\e911";
}
.bxl-css3:before {
  content: "\e912";
}
.bxl-dailymotion:before {
  content: "\e913";
}
.bxl-deviantart:before {
  content: "\e914";
}
.bxl-dev-to:before {
  content: "\e915";
}
.bxl-digg:before {
  content: "\e916";
}
.bxl-digitalocean:before {
  content: "\e917";
}
.bxl-discord:before {
  content: "\e918";
}
.bxl-discourse:before {
  content: "\e919";
}
.bxl-dribbble:before {
  content: "\e91a";
}
.bxl-dropbox:before {
  content: "\e91b";
}
.bxl-drupal:before {
  content: "\e91c";
}
.bxl-ebay:before {
  content: "\e91d";
}
.bxl-edge:before {
  content: "\e91e";
}
.bxl-etsy:before {
  content: "\e91f";
}
.bxl-facebook:before {
  content: "\e920";
}
.bxl-facebook-circle:before {
  content: "\e921";
}
.bxl-facebook-square:before {
  content: "\e922";
}
.bxl-figma:before {
  content: "\e923";
}
.bxl-firefox:before {
  content: "\e924";
}
.bxl-flickr:before {
  content: "\e925";
}
.bxl-flickr-square:before {
  content: "\e926";
}
.bxl-foursquare:before {
  content: "\e927";
}
.bxl-git:before {
  content: "\e928";
}
.bxl-github:before {
  content: "\e929";
}
.bxl-gitlab:before {
  content: "\e92a";
}
.bxl-google:before {
  content: "\e92b";
}
.bxl-google-plus:before {
  content: "\e92c";
}
.bxl-google-plus-circle:before {
  content: "\e92d";
}
.bxl-html5:before {
  content: "\e92e";
}
.bxl-imdb:before {
  content: "\e92f";
}
.bxl-instagram:before {
  content: "\e930";
}
.bxl-instagram-alt:before {
  content: "\e931";
}
.bxl-internet-explorer:before {
  content: "\e932";
}
.bxl-invision:before {
  content: "\e933";
}
.bxl-javascript:before {
  content: "\e934";
}
.bxl-joomla:before {
  content: "\e935";
}
.bxl-jquery:before {
  content: "\e936";
}
.bxl-jsfiddle:before {
  content: "\e937";
}
.bxl-kickstarter:before {
  content: "\e938";
}
.bxl-less:before {
  content: "\e939";
}
.bxl-linkedin:before {
  content: "\e93a";
}
.bxl-linkedin-square:before {
  content: "\e93b";
}
.bxl-magento:before {
  content: "\e93c";
}
.bxl-mailchimp:before {
  content: "\e93d";
}
.bxl-mastercard:before {
  content: "\e93e";
}
.bxl-medium:before {
  content: "\e93f";
}
.bxl-medium-old:before {
  content: "\e940";
}
.bxl-medium-square:before {
  content: "\e941";
}
.bxl-messenger:before {
  content: "\e942";
}
.bxl-microsoft:before {
  content: "\e943";
}
.bxl-nodejs:before {
  content: "\e944";
}
.bxl-opera:before {
  content: "\e945";
}
.bxl-patreon:before {
  content: "\e946";
}
.bxl-paypal:before {
  content: "\e947";
}
.bxl-periscope:before {
  content: "\e948";
}
.bxl-pinterest:before {
  content: "\e949";
}
.bxl-pinterest-alt:before {
  content: "\e94a";
}
.bxl-play-store:before {
  content: "\e94b";
}
.bxl-pocket:before {
  content: "\e94c";
}
.bxl-product-hunt:before {
  content: "\e94d";
}
.bxl-quora:before {
  content: "\e94e";
}
.bxl-react:before {
  content: "\e94f";
}
.bxl-redbubble:before {
  content: "\e950";
}
.bxl-reddit:before {
  content: "\e951";
}
.bxl-redux:before {
  content: "\e952";
}
.bxl-sass:before {
  content: "\e953";
}
.bxl-shopify:before {
  content: "\e954";
}
.bxl-skype:before {
  content: "\e955";
}
.bxl-slack:before {
  content: "\e956";
}
.bxl-slack-old:before {
  content: "\e957";
}
.bxl-snapchat:before {
  content: "\e958";
}
.bxl-soundcloud:before {
  content: "\e959";
}
.bxl-spotify:before {
  content: "\e95a";
}
.bxl-squarespace:before {
  content: "\e95b";
}
.bxl-stack-overflow:before {
  content: "\e95c";
}
.bxl-stripe:before {
  content: "\e95d";
}
.bxl-telegram:before {
  content: "\e95e";
}
.bxl-trello:before {
  content: "\e95f";
}
.bxl-tumblr:before {
  content: "\e960";
}
.bxl-twitch:before {
  content: "\e961";
}
.bxl-twitter:before {
  content: "\e962";
}
.bxl-unsplash:before {
  content: "\e963";
}
.bxl-vimeo:before {
  content: "\e964";
}
.bxl-visa:before {
  content: "\e965";
}
.bxl-vk:before {
  content: "\e966";
}
.bxl-vuejs:before {
  content: "\e967";
}
.bxl-whatsapp:before {
  content: "\e968";
}
.bxl-whatsapp-square:before {
  content: "\e969";
}
.bxl-wikipedia:before {
  content: "\e96a";
}
.bxl-windows:before {
  content: "\e96b";
}
.bxl-wix:before {
  content: "\e96c";
}
.bxl-wordpress:before {
  content: "\e96d";
}
.bxl-yahoo:before {
  content: "\e96e";
}
.bxl-yelp:before {
  content: "\e96f";
}
.bxl-youtube:before {
  content: "\e970";
}
.bx-abacus:before {
  content: "\e971";
}
.bx-accessibility:before {
  content: "\e972";
}
.bx-add-to-queue:before {
  content: "\e973";
}
.bx-adjust:before {
  content: "\e974";
}
.bx-alarm:before {
  content: "\e975";
}
.bx-alarm-add:before {
  content: "\e976";
}
.bx-alarm-exclamation:before {
  content: "\e977";
}
.bx-alarm-off:before {
  content: "\e978";
}
.bx-alarm-snooze:before {
  content: "\e979";
}
.bx-album:before {
  content: "\e97a";
}
.bx-align-justify:before {
  content: "\e97b";
}
.bx-align-left:before {
  content: "\e97c";
}
.bx-align-middle:before {
  content: "\e97d";
}
.bx-align-right:before {
  content: "\e97e";
}
.bx-analyse:before {
  content: "\e97f";
}
.bx-anchor:before {
  content: "\e980";
}
.bx-angry:before {
  content: "\e981";
}
.bx-aperture:before {
  content: "\e982";
}
.bx-arch:before {
  content: "\e983";
}
.bx-archive:before {
  content: "\e984";
}
.bx-archive-in:before {
  content: "\e985";
}
.bx-archive-out:before {
  content: "\e986";
}
.bx-area:before {
  content: "\e987";
}
.bx-arrow-back:before {
  content: "\e988";
}
.bx-arrow-from-bottom:before {
  content: "\e989";
}
.bx-arrow-from-left:before {
  content: "\e98a";
}
.bx-arrow-from-right:before {
  content: "\e98b";
}
.bx-arrow-from-top:before {
  content: "\e98c";
}
.bx-arrow-to-bottom:before {
  content: "\e98d";
}
.bx-arrow-to-left:before {
  content: "\e98e";
}
.bx-arrow-to-right:before {
  content: "\e98f";
}
.bx-arrow-to-top:before {
  content: "\e990";
}
.bx-at:before {
  content: "\e991";
}
.bx-atom:before {
  content: "\e992";
}
.bx-award:before {
  content: "\e993";
}
.bx-badge:before {
  content: "\e994";
}
.bx-badge-check:before {
  content: "\e995";
}
.bx-ball:before {
  content: "\e996";
}
.bx-band-aid:before {
  content: "\e997";
}
.bx-bar-chart:before {
  content: "\e998";
}
.bx-bar-chart-alt:before {
  content: "\e999";
}
.bx-bar-chart-alt-2:before {
  content: "\e99a";
}
.bx-bar-chart-square:before {
  content: "\e99b";
}
.bx-barcode:before {
  content: "\e99c";
}
.bx-barcode-reader:before {
  content: "\e99d";
}
.bx-baseball:before {
  content: "\e99e";
}
.bx-basket:before {
  content: "\e99f";
}
.bx-basketball:before {
  content: "\e9a0";
}
.bx-bath:before {
  content: "\e9a1";
}
.bx-battery:before {
  content: "\e9a2";
}
.bx-bed:before {
  content: "\e9a3";
}
.bx-been-here:before {
  content: "\e9a4";
}
.bx-beer:before {
  content: "\e9a5";
}
.bx-bell:before {
  content: "\e9a6";
}
.bx-bell-minus:before {
  content: "\e9a7";
}
.bx-bell-off:before {
  content: "\e9a8";
}
.bx-bell-plus:before {
  content: "\e9a9";
}
.bx-bible:before {
  content: "\e9aa";
}
.bx-bitcoin:before {
  content: "\e9ab";
}
.bx-blanket:before {
  content: "\e9ac";
}
.bx-block:before {
  content: "\e9ad";
}
.bx-bluetooth:before {
  content: "\e9ae";
}
.bx-body:before {
  content: "\e9af";
}
.bx-bold:before {
  content: "\e9b0";
}
.bx-bolt-circle:before {
  content: "\e9b1";
}
.bx-bomb:before {
  content: "\e9b2";
}
.bx-bone:before {
  content: "\e9b3";
}
.bx-bong:before {
  content: "\e9b4";
}
.bx-book:before {
  content: "\e9b5";
}
.bx-book-add:before {
  content: "\e9b6";
}
.bx-book-alt:before {
  content: "\e9b7";
}
.bx-book-bookmark:before {
  content: "\e9b8";
}
.bx-book-content:before {
  content: "\e9b9";
}
.bx-book-heart:before {
  content: "\e9ba";
}
.bx-bookmark:before {
  content: "\e9bb";
}
.bx-bookmark-minus:before {
  content: "\e9bc";
}
.bx-bookmark-plus:before {
  content: "\e9bd";
}
.bx-bookmarks:before {
  content: "\e9be";
}
.bx-book-open:before {
  content: "\e9bf";
}
.bx-book-reader:before {
  content: "\e9c0";
}
.bx-border-all:before {
  content: "\e9c1";
}
.bx-border-bottom:before {
  content: "\e9c2";
}
.bx-border-inner:before {
  content: "\e9c3";
}
.bx-border-left:before {
  content: "\e9c4";
}
.bx-border-none:before {
  content: "\e9c5";
}
.bx-border-outer:before {
  content: "\e9c6";
}
.bx-border-radius:before {
  content: "\e9c7";
}
.bx-border-right:before {
  content: "\e9c8";
}
.bx-border-top:before {
  content: "\e9c9";
}
.bx-bot:before {
  content: "\e9ca";
}
.bx-bowling-ball:before {
  content: "\e9cb";
}
.bx-box:before {
  content: "\e9cc";
}
.bx-bracket:before {
  content: "\e9cd";
}
.bx-braille:before {
  content: "\e9ce";
}
.bx-brain:before {
  content: "\e9cf";
}
.bx-briefcase:before {
  content: "\e9d0";
}
.bx-briefcase-alt:before {
  content: "\e9d1";
}
.bx-briefcase-alt-2:before {
  content: "\e9d2";
}
.bx-brightness:before {
  content: "\e9d3";
}
.bx-brightness-half:before {
  content: "\e9d4";
}
.bx-broadcast:before {
  content: "\e9d5";
}
.bx-brush:before {
  content: "\e9d6";
}
.bx-brush-alt:before {
  content: "\e9d7";
}
.bx-bug:before {
  content: "\e9d8";
}
.bx-bug-alt:before {
  content: "\e9d9";
}
.bx-building:before {
  content: "\e9da";
}
.bx-building-house:before {
  content: "\e9db";
}
.bx-buildings:before {
  content: "\e9dc";
}
.bx-bulb:before {
  content: "\e9dd";
}
.bx-bullseye:before {
  content: "\e9de";
}
.bx-buoy:before {
  content: "\e9df";
}
.bx-bus:before {
  content: "\e9e0";
}
.bx-bus-school:before {
  content: "\e9e1";
}
.bx-cabinet:before {
  content: "\e9e2";
}
.bx-cake:before {
  content: "\e9e3";
}
.bx-calculator:before {
  content: "\e9e4";
}
.bx-calendar:before {
  content: "\e9e5";
}
.bx-calendar-alt:before {
  content: "\e9e6";
}
.bx-calendar-check:before {
  content: "\e9e7";
}
.bx-calendar-edit:before {
  content: "\e9e8";
}
.bx-calendar-event:before {
  content: "\e9e9";
}
.bx-calendar-exclamation:before {
  content: "\e9ea";
}
.bx-calendar-heart:before {
  content: "\e9eb";
}
.bx-calendar-minus:before {
  content: "\e9ec";
}
.bx-calendar-plus:before {
  content: "\e9ed";
}
.bx-calendar-star:before {
  content: "\e9ee";
}
.bx-calendar-week:before {
  content: "\e9ef";
}
.bx-calendar-x:before {
  content: "\e9f0";
}
.bx-camera:before {
  content: "\e9f1";
}
.bx-camera-home:before {
  content: "\e9f2";
}
.bx-camera-movie:before {
  content: "\e9f3";
}
.bx-camera-off:before {
  content: "\e9f4";
}
.bx-capsule:before {
  content: "\e9f5";
}
.bx-captions:before {
  content: "\e9f6";
}
.bx-car:before {
  content: "\e9f7";
}
.bx-card:before {
  content: "\e9f8";
}
.bx-caret-down:before {
  content: "\e9f9";
}
.bx-caret-down-circle:before {
  content: "\e9fa";
}
.bx-caret-down-square:before {
  content: "\e9fb";
}
.bx-caret-left:before {
  content: "\e9fc";
}
.bx-caret-left-circle:before {
  content: "\e9fd";
}
.bx-caret-left-square:before {
  content: "\e9fe";
}
.bx-caret-right:before {
  content: "\e9ff";
}
.bx-caret-right-circle:before {
  content: "\ea00";
}
.bx-caret-right-square:before {
  content: "\ea01";
}
.bx-caret-up:before {
  content: "\ea02";
}
.bx-caret-up-circle:before {
  content: "\ea03";
}
.bx-caret-up-square:before {
  content: "\ea04";
}
.bx-carousel:before {
  content: "\ea05";
}
.bx-cart:before {
  content: "\ea06";
}
.bx-cart-alt:before {
  content: "\ea07";
}
.bx-cast:before {
  content: "\ea08";
}
.bx-cctv:before {
  content: "\ea09";
}
.bx-certification:before {
  content: "\ea0a";
}
.bx-chair:before {
  content: "\ea0b";
}
.bx-chalkboard:before {
  content: "\ea0c";
}
.bx-chart:before {
  content: "\ea0d";
}
.bx-chat:before {
  content: "\ea0e";
}
.bx-check:before {
  content: "\ea0f";
}
.bx-checkbox:before {
  content: "\ea10";
}
.bx-checkbox-checked:before {
  content: "\ea11";
}
.bx-checkbox-square:before {
  content: "\ea12";
}
.bx-check-circle:before {
  content: "\ea13";
}
.bx-check-double:before {
  content: "\ea14";
}
.bx-check-shield:before {
  content: "\ea15";
}
.bx-check-square:before {
  content: "\ea16";
}
.bx-chevron-down:before {
  content: "\ea17";
}
.bx-chevron-down-circle:before {
  content: "\ea18";
}
.bx-chevron-down-square:before {
  content: "\ea19";
}
.bx-chevron-left:before {
  content: "\ea1a";
}
.bx-chevron-left-circle:before {
  content: "\ea1b";
}
.bx-chevron-left-square:before {
  content: "\ea1c";
}
.bx-chevron-right:before {
  content: "\ea1d";
}
.bx-chevron-right-circle:before {
  content: "\ea1e";
}
.bx-chevron-right-square:before {
  content: "\ea1f";
}
.bx-chevrons-down:before {
  content: "\ea20";
}
.bx-chevrons-left:before {
  content: "\ea21";
}
.bx-chevrons-right:before {
  content: "\ea22";
}
.bx-chevrons-up:before {
  content: "\ea23";
}
.bx-chevron-up:before {
  content: "\ea24";
}
.bx-chevron-up-circle:before {
  content: "\ea25";
}
.bx-chevron-up-square:before {
  content: "\ea26";
}
.bx-chip:before {
  content: "\ea27";
}
.bx-church:before {
  content: "\ea28";
}
.bx-circle:before {
  content: "\ea29";
}
.bx-clinic:before {
  content: "\ea2a";
}
.bx-clipboard:before {
  content: "\ea2b";
}
.bx-closet:before {
  content: "\ea2c";
}
.bx-cloud:before {
  content: "\ea2d";
}
.bx-cloud-download:before {
  content: "\ea2e";
}
.bx-cloud-drizzle:before {
  content: "\ea2f";
}
.bx-cloud-lightning:before {
  content: "\ea30";
}
.bx-cloud-light-rain:before {
  content: "\ea31";
}
.bx-cloud-rain:before {
  content: "\ea32";
}
.bx-cloud-snow:before {
  content: "\ea33";
}
.bx-cloud-upload:before {
  content: "\ea34";
}
.bx-code:before {
  content: "\ea35";
}
.bx-code-alt:before {
  content: "\ea36";
}
.bx-code-block:before {
  content: "\ea37";
}
.bx-code-curly:before {
  content: "\ea38";
}
.bx-coffee:before {
  content: "\ea39";
}
.bx-coffee-togo:before {
  content: "\ea3a";
}
.bx-cog:before {
  content: "\ea3b";
}
.bx-coin:before {
  content: "\ea3c";
}
.bx-coin-stack:before {
  content: "\ea3d";
}
.bx-collapse:before {
  content: "\ea3e";
}
.bx-collection:before {
  content: "\ea3f";
}
.bx-color-fill:before {
  content: "\ea40";
}
.bx-columns:before {
  content: "\ea41";
}
.bx-command:before {
  content: "\ea42";
}
.bx-comment:before {
  content: "\ea43";
}
.bx-comment-add:before {
  content: "\ea44";
}
.bx-comment-check:before {
  content: "\ea45";
}
.bx-comment-detail:before {
  content: "\ea46";
}
.bx-comment-dots:before {
  content: "\ea47";
}
.bx-comment-edit:before {
  content: "\ea48";
}
.bx-comment-error:before {
  content: "\ea49";
}
.bx-comment-minus:before {
  content: "\ea4a";
}
.bx-comment-x:before {
  content: "\ea4b";
}
.bx-compass:before {
  content: "\ea4c";
}
.bx-confused:before {
  content: "\ea4d";
}
.bx-conversation:before {
  content: "\ea4e";
}
.bx-cookie:before {
  content: "\ea4f";
}
.bx-cool:before {
  content: "\ea50";
}
.bx-copy:before {
  content: "\ea51";
}
.bx-copy-alt:before {
  content: "\ea52";
}
.bx-copyright:before {
  content: "\ea53";
}
.bx-credit-card:before {
  content: "\ea54";
}
.bx-credit-card-alt:before {
  content: "\ea55";
}
.bx-credit-card-front:before {
  content: "\ea56";
}
.bx-crop:before {
  content: "\ea57";
}
.bx-crosshair:before {
  content: "\ea58";
}
.bx-crown:before {
  content: "\ea59";
}
.bx-cube:before {
  content: "\ea5a";
}
.bx-cube-alt:before {
  content: "\ea5b";
}
.bx-cuboid:before {
  content: "\ea5c";
}
.bx-current-location:before {
  content: "\ea5d";
}
.bx-customize:before {
  content: "\ea5e";
}
.bx-cut:before {
  content: "\ea5f";
}
.bx-cycling:before {
  content: "\ea60";
}
.bx-cylinder:before {
  content: "\ea61";
}
.bx-data:before {
  content: "\ea62";
}
.bx-desktop:before {
  content: "\ea63";
}
.bx-detail:before {
  content: "\ea64";
}
.bx-devices:before {
  content: "\ea65";
}
.bx-dialpad:before {
  content: "\ea66";
}
.bx-dialpad-alt:before {
  content: "\ea67";
}
.bx-diamond:before {
  content: "\ea68";
}
.bx-dice-1:before {
  content: "\ea69";
}
.bx-dice-2:before {
  content: "\ea6a";
}
.bx-dice-3:before {
  content: "\ea6b";
}
.bx-dice-4:before {
  content: "\ea6c";
}
.bx-dice-5:before {
  content: "\ea6d";
}
.bx-dice-6:before {
  content: "\ea6e";
}
.bx-directions:before {
  content: "\ea6f";
}
.bx-disc:before {
  content: "\ea70";
}
.bx-dish:before {
  content: "\ea71";
}
.bx-dislike:before {
  content: "\ea72";
}
.bx-dizzy:before {
  content: "\ea73";
}
.bx-dna:before {
  content: "\ea74";
}
.bx-dock-bottom:before {
  content: "\ea75";
}
.bx-dock-left:before {
  content: "\ea76";
}
.bx-dock-right:before {
  content: "\ea77";
}
.bx-dock-top:before {
  content: "\ea78";
}
.bx-dollar:before {
  content: "\ea79";
}
.bx-dollar-circle:before {
  content: "\ea7a";
}
.bx-donate-blood:before {
  content: "\ea7b";
}
.bx-donate-heart:before {
  content: "\ea7c";
}
.bx-door-open:before {
  content: "\ea7d";
}
.bx-dots-horizontal:before {
  content: "\ea7e";
}
.bx-dots-horizontal-rounded:before {
  content: "\ea7f";
}
.bx-dots-vertical:before {
  content: "\ea80";
}
.bx-dots-vertical-rounded:before {
  content: "\ea81";
}
.bx-doughnut-chart:before {
  content: "\ea82";
}
.bx-down-arrow:before {
  content: "\ea83";
}
.bx-down-arrow-alt:before {
  content: "\ea84";
}
.bx-down-arrow-circle:before {
  content: "\ea85";
}
.bx-download:before {
  content: "\ea86";
}
.bx-downvote:before {
  content: "\ea87";
}
.bx-drink:before {
  content: "\ea88";
}
.bx-droplet:before {
  content: "\ea89";
}
.bx-dumbbell:before {
  content: "\ea8a";
}
.bx-duplicate:before {
  content: "\ea8b";
}
.bx-edit:before {
  content: "\ea8c";
}
.bx-edit-alt:before {
  content: "\ea8d";
}
.bx-envelope:before {
  content: "\ea8e";
}
.bx-envelope-open:before {
  content: "\ea8f";
}
.bx-equalizer:before {
  content: "\ea90";
}
.bx-eraser:before {
  content: "\ea91";
}
.bx-error:before {
  content: "\ea92";
}
.bx-error-alt:before {
  content: "\ea93";
}
.bx-error-circle:before {
  content: "\ea94";
}
.bx-euro:before {
  content: "\ea95";
}
.bx-exclude:before {
  content: "\ea96";
}
.bx-exit:before {
  content: "\ea97";
}
.bx-exit-fullscreen:before {
  content: "\ea98";
}
.bx-expand:before {
  content: "\ea99";
}
.bx-export:before {
  content: "\ea9a";
}
.bx-extension:before {
  content: "\ea9b";
}
.bx-face:before {
  content: "\ea9c";
}
.bx-fast-forward:before {
  content: "\ea9d";
}
.bx-fast-forward-circle:before {
  content: "\ea9e";
}
.bx-female:before {
  content: "\ea9f";
}
.bx-female-sign:before {
  content: "\eaa0";
}
.bx-file:before {
  content: "\eaa1";
}
.bx-file-blank:before {
  content: "\eaa2";
}
.bx-file-find:before {
  content: "\eaa3";
}
.bx-film:before {
  content: "\eaa4";
}
.bx-filter:before {
  content: "\eaa5";
}
.bx-filter-alt:before {
  content: "\eaa6";
}
.bx-fingerprint:before {
  content: "\eaa7";
}
.bx-first-aid:before {
  content: "\eaa8";
}
.bx-first-page:before {
  content: "\eaa9";
}
.bx-flag:before {
  content: "\eaaa";
}
.bx-folder:before {
  content: "\eaab";
}
.bx-folder-minus:before {
  content: "\eaac";
}
.bx-folder-open:before {
  content: "\eaad";
}
.bx-folder-plus:before {
  content: "\eaae";
}
.bx-font:before {
  content: "\eaaf";
}
.bx-font-color:before {
  content: "\eab0";
}
.bx-font-family:before {
  content: "\eab1";
}
.bx-font-size:before {
  content: "\eab2";
}
.bx-food-menu:before {
  content: "\eab3";
}
.bx-food-tag:before {
  content: "\eab4";
}
.bx-football:before {
  content: "\eab5";
}
.bx-fridge:before {
  content: "\eab6";
}
.bx-fullscreen:before {
  content: "\eab7";
}
.bx-game:before {
  content: "\eab8";
}
.bx-gas-pump:before {
  content: "\eab9";
}
.bx-ghost:before {
  content: "\eaba";
}
.bx-gift:before {
  content: "\eabb";
}
.bx-git-branch:before {
  content: "\eabc";
}
.bx-git-commit:before {
  content: "\eabd";
}
.bx-git-compare:before {
  content: "\eabe";
}
.bx-git-merge:before {
  content: "\eabf";
}
.bx-git-pull-request:before {
  content: "\eac0";
}
.bx-git-repo-forked:before {
  content: "\eac1";
}
.bx-glasses:before {
  content: "\eac2";
}
.bx-glasses-alt:before {
  content: "\eac3";
}
.bx-globe:before {
  content: "\eac4";
}
.bx-globe-alt:before {
  content: "\eac5";
}
.bx-grid:before {
  content: "\eac6";
}
.bx-grid-alt:before {
  content: "\eac7";
}
.bx-grid-horizontal:before {
  content: "\eac8";
}
.bx-grid-small:before {
  content: "\eac9";
}
.bx-grid-vertical:before {
  content: "\eaca";
}
.bx-group:before {
  content: "\eacb";
}
.bx-handicap:before {
  content: "\eacc";
}
.bx-happy:before {
  content: "\eacd";
}
.bx-happy-alt:before {
  content: "\eace";
}
.bx-happy-beaming:before {
  content: "\eacf";
}
.bx-happy-heart-eyes:before {
  content: "\ead0";
}
.bx-hash:before {
  content: "\ead1";
}
.bx-hdd:before {
  content: "\ead2";
}
.bx-heading:before {
  content: "\ead3";
}
.bx-headphone:before {
  content: "\ead4";
}
.bx-health:before {
  content: "\ead5";
}
.bx-heart:before {
  content: "\ead6";
}
.bx-heart-circle:before {
  content: "\ead7";
}
.bx-heart-square:before {
  content: "\ead8";
}
.bx-help-circle:before {
  content: "\ead9";
}
.bx-hide:before {
  content: "\eada";
}
.bx-highlight:before {
  content: "\eadb";
}
.bx-history:before {
  content: "\eadc";
}
.bx-hive:before {
  content: "\eadd";
}
.bx-home:before {
  content: "\eade";
}
.bx-home-alt:before {
  content: "\eadf";
}
.bx-home-circle:before {
  content: "\eae0";
}
.bx-home-heart:before {
  content: "\eae1";
}
.bx-home-smile:before {
  content: "\eae2";
}
.bx-horizontal-center:before {
  content: "\eae3";
}
.bx-hotel:before {
  content: "\eae4";
}
.bx-hourglass:before {
  content: "\eae5";
}
.bx-id-card:before {
  content: "\eae6";
}
.bx-image:before {
  content: "\eae7";
}
.bx-image-add:before {
  content: "\eae8";
}
.bx-image-alt:before {
  content: "\eae9";
}
.bx-images:before {
  content: "\eaea";
}
.bx-import:before {
  content: "\eaeb";
}
.bx-infinite:before {
  content: "\eaec";
}
.bx-info-circle:before {
  content: "\eaed";
}
.bx-info-square:before {
  content: "\eaee";
}
.bx-intersect:before {
  content: "\eaef";
}
.bx-italic:before {
  content: "\eaf0";
}
.bx-joystick:before {
  content: "\eaf1";
}
.bx-joystick-alt:before {
  content: "\eaf2";
}
.bx-joystick-button:before {
  content: "\eaf3";
}
.bx-key:before {
  content: "\eaf4";
}
.bx-label:before {
  content: "\eaf5";
}
.bx-landscape:before {
  content: "\eaf6";
}
.bx-laptop:before {
  content: "\eaf7";
}
.bx-last-page:before {
  content: "\eaf8";
}
.bx-laugh:before {
  content: "\eaf9";
}
.bx-layer:before {
  content: "\eafa";
}
.bx-layer-minus:before {
  content: "\eafb";
}
.bx-layer-plus:before {
  content: "\eafc";
}
.bx-layout:before {
  content: "\eafd";
}
.bx-left-arrow:before {
  content: "\eafe";
}
.bx-left-arrow-alt:before {
  content: "\eaff";
}
.bx-left-arrow-circle:before {
  content: "\eb00";
}
.bx-left-down-arrow-circle:before {
  content: "\eb01";
}
.bx-left-indent:before {
  content: "\eb02";
}
.bx-left-top-arrow-circle:before {
  content: "\eb03";
}
.bx-like:before {
  content: "\eb04";
}
.bx-line-chart:before {
  content: "\eb05";
}
.bx-line-chart-down:before {
  content: "\eb06";
}
.bx-link:before {
  content: "\eb07";
}
.bx-link-alt:before {
  content: "\eb08";
}
.bx-link-external:before {
  content: "\eb09";
}
.bx-lira:before {
  content: "\eb0a";
}
.bx-list-check:before {
  content: "\eb0b";
}
.bx-list-minus:before {
  content: "\eb0c";
}
.bx-list-ol:before {
  content: "\eb0d";
}
.bx-list-plus:before {
  content: "\eb0e";
}
.bx-list-ul:before {
  content: "\eb0f";
}
.bx-loader:before {
  content: "\eb10";
}
.bx-loader-alt:before {
  content: "\eb11";
}
.bx-loader-circle:before {
  content: "\eb12";
}
.bx-location-plus:before {
  content: "\eb13";
}
.bx-lock:before {
  content: "\eb14";
}
.bx-lock-alt:before {
  content: "\eb15";
}
.bx-lock-open:before {
  content: "\eb16";
}
.bx-lock-open-alt:before {
  content: "\eb17";
}
.bx-log-in:before {
  content: "\eb18";
}
.bx-log-in-circle:before {
  content: "\eb19";
}
.bx-log-out:before {
  content: "\eb1a";
}
.bx-log-out-circle:before {
  content: "\eb1b";
}
.bx-low-vision:before {
  content: "\eb1c";
}
.bx-magnet:before {
  content: "\eb1d";
}
.bx-mail-send:before {
  content: "\eb1e";
}
.bx-male:before {
  content: "\eb1f";
}
.bx-male-sign:before {
  content: "\eb20";
}
.bx-map:before {
  content: "\eb21";
}
.bx-map-alt:before {
  content: "\eb22";
}
.bx-map-pin:before {
  content: "\eb23";
}
.bx-mask:before {
  content: "\eb24";
}
.bx-medal:before {
  content: "\eb25";
}
.bx-meh:before {
  content: "\eb26";
}
.bx-meh-alt:before {
  content: "\eb27";
}
.bx-meh-blank:before {
  content: "\eb28";
}
.bx-memory-card:before {
  content: "\eb29";
}
.bx-menu:before {
  content: "\eb2a";
}
.bx-menu-alt-left:before {
  content: "\eb2b";
}
.bx-menu-alt-right:before {
  content: "\eb2c";
}
.bx-merge:before {
  content: "\eb2d";
}
.bx-message:before {
  content: "\eb2e";
}
.bx-message-add:before {
  content: "\eb2f";
}
.bx-message-alt:before {
  content: "\eb30";
}
.bx-message-alt-add:before {
  content: "\eb31";
}
.bx-message-alt-check:before {
  content: "\eb32";
}
.bx-message-alt-detail:before {
  content: "\eb33";
}
.bx-message-alt-dots:before {
  content: "\eb34";
}
.bx-message-alt-edit:before {
  content: "\eb35";
}
.bx-message-alt-error:before {
  content: "\eb36";
}
.bx-message-alt-minus:before {
  content: "\eb37";
}
.bx-message-alt-x:before {
  content: "\eb38";
}
.bx-message-check:before {
  content: "\eb39";
}
.bx-message-detail:before {
  content: "\eb3a";
}
.bx-message-dots:before {
  content: "\eb3b";
}
.bx-message-edit:before {
  content: "\eb3c";
}
.bx-message-error:before {
  content: "\eb3d";
}
.bx-message-minus:before {
  content: "\eb3e";
}
.bx-message-rounded:before {
  content: "\eb3f";
}
.bx-message-rounded-add:before {
  content: "\eb40";
}
.bx-message-rounded-check:before {
  content: "\eb41";
}
.bx-message-rounded-detail:before {
  content: "\eb42";
}
.bx-message-rounded-dots:before {
  content: "\eb43";
}
.bx-message-rounded-edit:before {
  content: "\eb44";
}
.bx-message-rounded-error:before {
  content: "\eb45";
}
.bx-message-rounded-minus:before {
  content: "\eb46";
}
.bx-message-rounded-x:before {
  content: "\eb47";
}
.bx-message-square:before {
  content: "\eb48";
}
.bx-message-square-add:before {
  content: "\eb49";
}
.bx-message-square-check:before {
  content: "\eb4a";
}
.bx-message-square-detail:before {
  content: "\eb4b";
}
.bx-message-square-dots:before {
  content: "\eb4c";
}
.bx-message-square-edit:before {
  content: "\eb4d";
}
.bx-message-square-error:before {
  content: "\eb4e";
}
.bx-message-square-minus:before {
  content: "\eb4f";
}
.bx-message-square-x:before {
  content: "\eb50";
}
.bx-message-x:before {
  content: "\eb51";
}
.bx-meteor:before {
  content: "\eb52";
}
.bx-microchip:before {
  content: "\eb53";
}
.bx-microphone:before {
  content: "\eb54";
}
.bx-microphone-off:before {
  content: "\eb55";
}
.bx-minus:before {
  content: "\eb56";
}
.bx-minus-back:before {
  content: "\eb57";
}
.bx-minus-circle:before {
  content: "\eb58";
}
.bx-minus-front:before {
  content: "\eb59";
}
.bx-mobile:before {
  content: "\eb5a";
}
.bx-mobile-alt:before {
  content: "\eb5b";
}
.bx-mobile-landscape:before {
  content: "\eb5c";
}
.bx-mobile-vibration:before {
  content: "\eb5d";
}
.bx-money:before {
  content: "\eb5e";
}
.bx-moon:before {
  content: "\eb5f";
}
.bx-mouse:before {
  content: "\eb60";
}
.bx-mouse-alt:before {
  content: "\eb61";
}
.bx-move:before {
  content: "\eb62";
}
.bx-move-horizontal:before {
  content: "\eb63";
}
.bx-move-vertical:before {
  content: "\eb64";
}
.bx-movie:before {
  content: "\eb65";
}
.bx-music:before {
  content: "\eb66";
}
.bx-navigation:before {
  content: "\eb67";
}
.bx-network-chart:before {
  content: "\eb68";
}
.bx-news:before {
  content: "\eb69";
}
.bx-no-entry:before {
  content: "\eb6a";
}
.bx-note:before {
  content: "\eb6b";
}
.bx-notepad:before {
  content: "\eb6c";
}
.bx-notification:before {
  content: "\eb6d";
}
.bx-notification-off:before {
  content: "\eb6e";
}
.bx-outline:before {
  content: "\eb6f";
}
.bx-package:before {
  content: "\eb70";
}
.bx-paint:before {
  content: "\eb71";
}
.bx-paint-roll:before {
  content: "\eb72";
}
.bx-palette:before {
  content: "\eb73";
}
.bx-paperclip:before {
  content: "\eb74";
}
.bx-paper-plane:before {
  content: "\eb75";
}
.bx-paragraph:before {
  content: "\eb76";
}
.bx-paste:before {
  content: "\eb77";
}
.bx-pause:before {
  content: "\eb78";
}
.bx-pause-circle:before {
  content: "\eb79";
}
.bx-pen:before {
  content: "\eb7a";
}
.bx-pencil:before {
  content: "\eb7b";
}
.bx-phone:before {
  content: "\eb7c";
}
.bx-phone-call:before {
  content: "\eb7d";
}
.bx-phone-incoming:before {
  content: "\eb7e";
}
.bx-phone-outgoing:before {
  content: "\eb7f";
}
.bx-photo-album:before {
  content: "\eb80";
}
.bx-pie-chart:before {
  content: "\eb81";
}
.bx-pie-chart-alt:before {
  content: "\eb82";
}
.bx-pie-chart-alt-2:before {
  content: "\eb83";
}
.bx-pin:before {
  content: "\eb84";
}
.bx-planet:before {
  content: "\eb85";
}
.bx-play:before {
  content: "\eb86";
}
.bx-play-circle:before {
  content: "\eb87";
}
.bx-plug:before {
  content: "\eb88";
}
.bx-plus:before {
  content: "\eb89";
}
.bx-plus-circle:before {
  content: "\eb8a";
}
.bx-plus-medical:before {
  content: "\eb8b";
}
.bx-pointer:before {
  content: "\eb8c";
}
.bx-poll:before {
  content: "\eb8d";
}
.bx-polygon:before {
  content: "\eb8e";
}
.bx-pound:before {
  content: "\eb8f";
}
.bx-power-off:before {
  content: "\eb90";
}
.bx-printer:before {
  content: "\eb91";
}
.bx-pulse:before {
  content: "\eb92";
}
.bx-purchase-tag:before {
  content: "\eb93";
}
.bx-purchase-tag-alt:before {
  content: "\eb94";
}
.bx-pyramid:before {
  content: "\eb95";
}
.bx-question-mark:before {
  content: "\eb96";
}
.bx-radar:before {
  content: "\eb97";
}
.bx-radio:before {
  content: "\eb98";
}
.bx-radio-circle:before {
  content: "\eb99";
}
.bx-radio-circle-marked:before {
  content: "\eb9a";
}
.bx-receipt:before {
  content: "\eb9b";
}
.bx-rectangle:before {
  content: "\eb9c";
}
.bx-recycle:before {
  content: "\eb9d";
}
.bx-redo:before {
  content: "\eb9e";
}
.bx-refresh:before {
  content: "\eb9f";
}
.bx-rename:before {
  content: "\eba0";
}
.bx-repeat:before {
  content: "\eba1";
}
.bx-reply:before {
  content: "\eba2";
}
.bx-reply-all:before {
  content: "\eba3";
}
.bx-repost:before {
  content: "\eba4";
}
.bx-reset:before {
  content: "\eba5";
}
.bx-restaurant:before {
  content: "\eba6";
}
.bx-revision:before {
  content: "\eba7";
}
.bx-rewind:before {
  content: "\eba8";
}
.bx-rewind-circle:before {
  content: "\eba9";
}
.bx-right-arrow:before {
  content: "\ebaa";
}
.bx-right-arrow-alt:before {
  content: "\ebab";
}
.bx-right-arrow-circle:before {
  content: "\ebac";
}
.bx-right-down-arrow-circle:before {
  content: "\ebad";
}
.bx-right-indent:before {
  content: "\ebae";
}
.bx-right-top-arrow-circle:before {
  content: "\ebaf";
}
.bx-rocket:before {
  content: "\ebb0";
}
.bx-rotate-left:before {
  content: "\ebb1";
}
.bx-rotate-right:before {
  content: "\ebb2";
}
.bx-rss:before {
  content: "\ebb3";
}
.bx-ruble:before {
  content: "\ebb4";
}
.bx-ruler:before {
  content: "\ebb5";
}
.bx-run:before {
  content: "\ebb6";
}
.bx-rupee:before {
  content: "\ebb7";
}
.bx-sad:before {
  content: "\ebb8";
}
.bx-save:before {
  content: "\ebb9";
}
.bx-scan:before {
  content: "\ebba";
}
.bx-screenshot:before {
  content: "\ebbb";
}
.bx-search:before {
  content: "\ebbc";
}
.bx-search-alt:before {
  content: "\ebbd";
}
.bx-search-alt-2:before {
  content: "\ebbe";
}
.bx-selection:before {
  content: "\ebbf";
}
.bx-select-multiple:before {
  content: "\ebc0";
}
.bx-send:before {
  content: "\ebc1";
}
.bx-server:before {
  content: "\ebc2";
}
.bx-shape-circle:before {
  content: "\ebc3";
}
.bx-shape-polygon:before {
  content: "\ebc4";
}
.bx-shape-square:before {
  content: "\ebc5";
}
.bx-shape-triangle:before {
  content: "\ebc6";
}
.bx-share:before {
  content: "\ebc7";
}
.bx-share-alt:before {
  content: "\ebc8";
}
.bx-shekel:before {
  content: "\ebc9";
}
.bx-shield:before {
  content: "\ebca";
}
.bx-shield-alt:before {
  content: "\ebcb";
}
.bx-shield-alt-2:before {
  content: "\ebcc";
}
.bx-shield-quarter:before {
  content: "\ebcd";
}
.bx-shield-x:before {
  content: "\ebce";
}
.bx-shocked:before {
  content: "\ebcf";
}
.bx-shopping-bag:before {
  content: "\ebd0";
}
.bx-show:before {
  content: "\ebd1";
}
.bx-show-alt:before {
  content: "\ebd2";
}
.bx-shuffle:before {
  content: "\ebd3";
}
.bx-sidebar:before {
  content: "\ebd4";
}
.bx-sitemap:before {
  content: "\ebd5";
}
.bx-skip-next:before {
  content: "\ebd6";
}
.bx-skip-next-circle:before {
  content: "\ebd7";
}
.bx-skip-previous:before {
  content: "\ebd8";
}
.bx-skip-previous-circle:before {
  content: "\ebd9";
}
.bx-sleepy:before {
  content: "\ebda";
}
.bx-slider:before {
  content: "\ebdb";
}
.bx-slider-alt:before {
  content: "\ebdc";
}
.bx-slideshow:before {
  content: "\ebdd";
}
.bx-smile:before {
  content: "\ebde";
}
.bx-sort:before {
  content: "\ebdf";
}
.bx-sort-a-z:before {
  content: "\ebe0";
}
.bx-sort-down:before {
  content: "\ebe1";
}
.bx-sort-up:before {
  content: "\ebe2";
}
.bx-sort-z-a:before {
  content: "\ebe3";
}
.bx-spa:before {
  content: "\ebe4";
}
.bx-space-bar:before {
  content: "\ebe5";
}
.bx-spray-can:before {
  content: "\ebe6";
}
.bx-spreadsheet:before {
  content: "\ebe7";
}
.bx-square:before {
  content: "\ebe8";
}
.bx-square-rounded:before {
  content: "\ebe9";
}
.bx-star:before {
  content: "\ebea";
}
.bx-station:before {
  content: "\ebeb";
}
.bx-stats:before {
  content: "\ebec";
}
.bx-sticker:before {
  content: "\ebed";
}
.bx-stop:before {
  content: "\ebee";
}
.bx-stop-circle:before {
  content: "\ebef";
}
.bx-stopwatch:before {
  content: "\ebf0";
}
.bx-store:before {
  content: "\ebf1";
}
.bx-store-alt:before {
  content: "\ebf2";
}
.bx-street-view:before {
  content: "\ebf3";
}
.bx-strikethrough:before {
  content: "\ebf4";
}
.bx-subdirectory-left:before {
  content: "\ebf5";
}
.bx-subdirectory-right:before {
  content: "\ebf6";
}
.bx-sun:before {
  content: "\ebf7";
}
.bx-support:before {
  content: "\ebf8";
}
.bx-swim:before {
  content: "\ebf9";
}
.bx-sync:before {
  content: "\ebfa";
}
.bx-tab:before {
  content: "\ebfb";
}
.bx-table:before {
  content: "\ebfc";
}
.bx-tachometer:before {
  content: "\ebfd";
}
.bx-tag:before {
  content: "\ebfe";
}
.bx-target-lock:before {
  content: "\ebff";
}
.bx-task:before {
  content: "\ec00";
}
.bx-task-x:before {
  content: "\ec01";
}
.bx-taxi:before {
  content: "\ec02";
}
.bx-tennis-ball:before {
  content: "\ec03";
}
.bx-terminal:before {
  content: "\ec04";
}
.bx-test-tube:before {
  content: "\ec05";
}
.bx-text:before {
  content: "\ec06";
}
.bx-time:before {
  content: "\ec07";
}
.bx-time-five:before {
  content: "\ec08";
}
.bx-timer:before {
  content: "\ec09";
}
.bx-tired:before {
  content: "\ec0a";
}
.bx-toggle-left:before {
  content: "\ec0b";
}
.bx-toggle-right:before {
  content: "\ec0c";
}
.bx-tone:before {
  content: "\ec0d";
}
.bx-traffic-cone:before {
  content: "\ec0e";
}
.bx-train:before {
  content: "\ec0f";
}
.bx-transfer:before {
  content: "\ec10";
}
.bx-transfer-alt:before {
  content: "\ec11";
}
.bx-trash:before {
  content: "\ec12";
}
.bx-trash-alt:before {
  content: "\ec13";
}
.bx-trending-down:before {
  content: "\ec14";
}
.bx-trending-up:before {
  content: "\ec15";
}
.bx-trim:before {
  content: "\ec16";
}
.bx-trophy:before {
  content: "\ec17";
}
.bx-tv:before {
  content: "\ec18";
}
.bx-underline:before {
  content: "\ec19";
}
.bx-undo:before {
  content: "\ec1a";
}
.bx-unite:before {
  content: "\ec1b";
}
.bx-unlink:before {
  content: "\ec1c";
}
.bx-up-arrow:before {
  content: "\ec1d";
}
.bx-up-arrow-alt:before {
  content: "\ec1e";
}
.bx-up-arrow-circle:before {
  content: "\ec1f";
}
.bx-upload:before {
  content: "\ec20";
}
.bx-upside-down:before {
  content: "\ec21";
}
.bx-upvote:before {
  content: "\ec22";
}
.bx-usb:before {
  content: "\ec23";
}
.bx-user:before {
  content: "\ec24";
}
.bx-user-check:before {
  content: "\ec25";
}
.bx-user-circle:before {
  content: "\ec26";
}
.bx-user-minus:before {
  content: "\ec27";
}
.bx-user-pin:before {
  content: "\ec28";
}
.bx-user-plus:before {
  content: "\ec29";
}
.bx-user-voice:before {
  content: "\ec2a";
}
.bx-user-x:before {
  content: "\ec2b";
}
.bx-vector:before {
  content: "\ec2c";
}
.bx-vertical-center:before {
  content: "\ec2d";
}
.bx-vial:before {
  content: "\ec2e";
}
.bx-video:before {
  content: "\ec2f";
}
.bx-video-off:before {
  content: "\ec30";
}
.bx-video-plus:before {
  content: "\ec31";
}
.bx-video-recording:before {
  content: "\ec32";
}
.bx-voicemail:before {
  content: "\ec33";
}
.bx-volume:before {
  content: "\ec34";
}
.bx-volume-full:before {
  content: "\ec35";
}
.bx-volume-low:before {
  content: "\ec36";
}
.bx-volume-mute:before {
  content: "\ec37";
}
.bx-walk:before {
  content: "\ec38";
}
.bx-wallet:before {
  content: "\ec39";
}
.bx-wallet-alt:before {
  content: "\ec3a";
}
.bx-water:before {
  content: "\ec3b";
}
.bx-webcam:before {
  content: "\ec3c";
}
.bx-wifi:before {
  content: "\ec3d";
}
.bx-wifi-0:before {
  content: "\ec3e";
}
.bx-wifi-1:before {
  content: "\ec3f";
}
.bx-wifi-2:before {
  content: "\ec40";
}
.bx-wifi-off:before {
  content: "\ec41";
}
.bx-wind:before {
  content: "\ec42";
}
.bx-window:before {
  content: "\ec43";
}
.bx-window-alt:before {
  content: "\ec44";
}
.bx-window-close:before {
  content: "\ec45";
}
.bx-window-open:before {
  content: "\ec46";
}
.bx-windows:before {
  content: "\ec47";
}
.bx-wine:before {
  content: "\ec48";
}
.bx-wink-smile:before {
  content: "\ec49";
}
.bx-wink-tongue:before {
  content: "\ec4a";
}
.bx-won:before {
  content: "\ec4b";
}
.bx-world:before {
  content: "\ec4c";
}
.bx-wrench:before {
  content: "\ec4d";
}
.bx-x:before {
  content: "\ec4e";
}
.bx-x-circle:before {
  content: "\ec4f";
}
.bx-yen:before {
  content: "\ec50";
}
.bx-zoom-in:before {
  content: "\ec51";
}
.bx-zoom-out:before {
  content: "\ec52";
}
.bxs-add-to-queue:before {
  content: "\ec53";
}
.bxs-adjust:before {
  content: "\ec54";
}
.bxs-adjust-alt:before {
  content: "\ec55";
}
.bxs-alarm:before {
  content: "\ec56";
}
.bxs-alarm-add:before {
  content: "\ec57";
}
.bxs-alarm-exclamation:before {
  content: "\ec58";
}
.bxs-alarm-off:before {
  content: "\ec59";
}
.bxs-alarm-snooze:before {
  content: "\ec5a";
}
.bxs-album:before {
  content: "\ec5b";
}
.bxs-ambulance:before {
  content: "\ec5c";
}
.bxs-analyse:before {
  content: "\ec5d";
}
.bxs-angry:before {
  content: "\ec5e";
}
.bxs-arch:before {
  content: "\ec5f";
}
.bxs-archive:before {
  content: "\ec60";
}
.bxs-archive-in:before {
  content: "\ec61";
}
.bxs-archive-out:before {
  content: "\ec62";
}
.bxs-area:before {
  content: "\ec63";
}
.bxs-arrow-from-bottom:before {
  content: "\ec64";
}
.bxs-arrow-from-left:before {
  content: "\ec65";
}
.bxs-arrow-from-right:before {
  content: "\ec66";
}
.bxs-arrow-from-top:before {
  content: "\ec67";
}
.bxs-arrow-to-bottom:before {
  content: "\ec68";
}
.bxs-arrow-to-left:before {
  content: "\ec69";
}
.bxs-arrow-to-right:before {
  content: "\ec6a";
}
.bxs-arrow-to-top:before {
  content: "\ec6b";
}
.bxs-award:before {
  content: "\ec6c";
}
.bxs-baby-carriage:before {
  content: "\ec6d";
}
.bxs-backpack:before {
  content: "\ec6e";
}
.bxs-badge:before {
  content: "\ec6f";
}
.bxs-badge-check:before {
  content: "\ec70";
}
.bxs-badge-dollar:before {
  content: "\ec71";
}
.bxs-ball:before {
  content: "\ec72";
}
.bxs-band-aid:before {
  content: "\ec73";
}
.bxs-bank:before {
  content: "\ec74";
}
.bxs-bar-chart-alt-2:before {
  content: "\ec75";
}
.bxs-bar-chart-square:before {
  content: "\ec76";
}
.bxs-barcode:before {
  content: "\ec77";
}
.bxs-baseball:before {
  content: "\ec78";
}
.bxs-basket:before {
  content: "\ec79";
}
.bxs-basketball:before {
  content: "\ec7a";
}
.bxs-bath:before {
  content: "\ec7b";
}
.bxs-battery:before {
  content: "\ec7c";
}
.bxs-battery-charging:before {
  content: "\ec7d";
}
.bxs-battery-full:before {
  content: "\ec7e";
}
.bxs-battery-low:before {
  content: "\ec7f";
}
.bxs-bed:before {
  content: "\ec80";
}
.bxs-been-here:before {
  content: "\ec81";
}
.bxs-beer:before {
  content: "\ec82";
}
.bxs-bell:before {
  content: "\ec83";
}
.bxs-bell-minus:before {
  content: "\ec84";
}
.bxs-bell-off:before {
  content: "\ec85";
}
.bxs-bell-plus:before {
  content: "\ec86";
}
.bxs-bell-ring:before {
  content: "\ec87";
}
.bxs-bible:before {
  content: "\ec88";
}
.bxs-binoculars:before {
  content: "\ec89";
}
.bxs-blanket:before {
  content: "\ec8a";
}
.bxs-bolt:before {
  content: "\ec8b";
}
.bxs-bolt-circle:before {
  content: "\ec8c";
}
.bxs-bomb:before {
  content: "\ec8d";
}
.bxs-bone:before {
  content: "\ec8e";
}
.bxs-bong:before {
  content: "\ec8f";
}
.bxs-book:before {
  content: "\ec90";
}
.bxs-book-add:before {
  content: "\ec91";
}
.bxs-book-alt:before {
  content: "\ec92";
}
.bxs-book-bookmark:before {
  content: "\ec93";
}
.bxs-book-content:before {
  content: "\ec94";
}
.bxs-book-heart:before {
  content: "\ec95";
}
.bxs-bookmark:before {
  content: "\ec96";
}
.bxs-bookmark-minus:before {
  content: "\ec97";
}
.bxs-bookmark-plus:before {
  content: "\ec98";
}
.bxs-bookmarks:before {
  content: "\ec99";
}
.bxs-bookmark-star:before {
  content: "\ec9a";
}
.bxs-book-open:before {
  content: "\ec9b";
}
.bxs-book-reader:before {
  content: "\ec9c";
}
.bxs-bot:before {
  content: "\ec9d";
}
.bxs-bowling-ball:before {
  content: "\ec9e";
}
.bxs-box:before {
  content: "\ec9f";
}
.bxs-brain:before {
  content: "\eca0";
}
.bxs-briefcase:before {
  content: "\eca1";
}
.bxs-briefcase-alt:before {
  content: "\eca2";
}
.bxs-briefcase-alt-2:before {
  content: "\eca3";
}
.bxs-brightness:before {
  content: "\eca4";
}
.bxs-brightness-half:before {
  content: "\eca5";
}
.bxs-brush:before {
  content: "\eca6";
}
.bxs-brush-alt:before {
  content: "\eca7";
}
.bxs-bug:before {
  content: "\eca8";
}
.bxs-bug-alt:before {
  content: "\eca9";
}
.bxs-building:before {
  content: "\ecaa";
}
.bxs-building-house:before {
  content: "\ecab";
}
.bxs-buildings:before {
  content: "\ecac";
}
.bxs-bulb:before {
  content: "\ecad";
}
.bxs-bullseye:before {
  content: "\ecae";
}
.bxs-buoy:before {
  content: "\ecaf";
}
.bxs-bus:before {
  content: "\ecb0";
}
.bxs-business:before {
  content: "\ecb1";
}
.bxs-bus-school:before {
  content: "\ecb2";
}
.bxs-cabinet:before {
  content: "\ecb3";
}
.bxs-cake:before {
  content: "\ecb4";
}
.bxs-calculator:before {
  content: "\ecb5";
}
.bxs-calendar:before {
  content: "\ecb6";
}
.bxs-calendar-alt:before {
  content: "\ecb7";
}
.bxs-calendar-check:before {
  content: "\ecb8";
}
.bxs-calendar-edit:before {
  content: "\ecb9";
}
.bxs-calendar-event:before {
  content: "\ecba";
}
.bxs-calendar-exclamation:before {
  content: "\ecbb";
}
.bxs-calendar-heart:before {
  content: "\ecbc";
}
.bxs-calendar-minus:before {
  content: "\ecbd";
}
.bxs-calendar-plus:before {
  content: "\ecbe";
}
.bxs-calendar-star:before {
  content: "\ecbf";
}
.bxs-calendar-week:before {
  content: "\ecc0";
}
.bxs-calendar-x:before {
  content: "\ecc1";
}
.bxs-camera:before {
  content: "\ecc2";
}
.bxs-camera-home:before {
  content: "\ecc3";
}
.bxs-camera-movie:before {
  content: "\ecc4";
}
.bxs-camera-off:before {
  content: "\ecc5";
}
.bxs-camera-plus:before {
  content: "\ecc6";
}
.bxs-capsule:before {
  content: "\ecc7";
}
.bxs-captions:before {
  content: "\ecc8";
}
.bxs-car:before {
  content: "\ecc9";
}
.bxs-car-battery:before {
  content: "\ecca";
}
.bxs-car-crash:before {
  content: "\eccb";
}
.bxs-card:before {
  content: "\eccc";
}
.bxs-caret-down-circle:before {
  content: "\eccd";
}
.bxs-caret-down-square:before {
  content: "\ecce";
}
.bxs-caret-left-circle:before {
  content: "\eccf";
}
.bxs-caret-left-square:before {
  content: "\ecd0";
}
.bxs-caret-right-circle:before {
  content: "\ecd1";
}
.bxs-caret-right-square:before {
  content: "\ecd2";
}
.bxs-caret-up-circle:before {
  content: "\ecd3";
}
.bxs-caret-up-square:before {
  content: "\ecd4";
}
.bxs-car-garage:before {
  content: "\ecd5";
}
.bxs-car-mechanic:before {
  content: "\ecd6";
}
.bxs-carousel:before {
  content: "\ecd7";
}
.bxs-cart:before {
  content: "\ecd8";
}
.bxs-cart-add:before {
  content: "\ecd9";
}
.bxs-cart-alt:before {
  content: "\ecda";
}
.bxs-cart-download:before {
  content: "\ecdb";
}
.bxs-car-wash:before {
  content: "\ecdc";
}
.bxs-cctv:before {
  content: "\ecdd";
}
.bxs-certification:before {
  content: "\ecde";
}
.bxs-chalkboard:before {
  content: "\ecdf";
}
.bxs-chart:before {
  content: "\ece0";
}
.bxs-chat:before {
  content: "\ece1";
}
.bxs-checkbox:before {
  content: "\ece2";
}
.bxs-checkbox-checked:before {
  content: "\ece3";
}
.bxs-check-circle:before {
  content: "\ece4";
}
.bxs-check-shield:before {
  content: "\ece5";
}
.bxs-check-square:before {
  content: "\ece6";
}
.bxs-chess:before {
  content: "\ece7";
}
.bxs-chevron-down:before {
  content: "\ece8";
}
.bxs-chevron-down-circle:before {
  content: "\ece9";
}
.bxs-chevron-down-square:before {
  content: "\ecea";
}
.bxs-chevron-left:before {
  content: "\eceb";
}
.bxs-chevron-left-circle:before {
  content: "\ecec";
}
.bxs-chevron-left-square:before {
  content: "\eced";
}
.bxs-chevron-right:before {
  content: "\ecee";
}
.bxs-chevron-right-circle:before {
  content: "\ecef";
}
.bxs-chevron-right-square:before {
  content: "\ecf0";
}
.bxs-chevrons-down:before {
  content: "\ecf1";
}
.bxs-chevrons-left:before {
  content: "\ecf2";
}
.bxs-chevrons-right:before {
  content: "\ecf3";
}
.bxs-chevrons-up:before {
  content: "\ecf4";
}
.bxs-chevron-up:before {
  content: "\ecf5";
}
.bxs-chevron-up-circle:before {
  content: "\ecf6";
}
.bxs-chevron-up-square:before {
  content: "\ecf7";
}
.bxs-chip:before {
  content: "\ecf8";
}
.bxs-church:before {
  content: "\ecf9";
}
.bxs-circle:before {
  content: "\ecfa";
}
.bxs-city:before {
  content: "\ecfb";
}
.bxs-clinic:before {
  content: "\ecfc";
}
.bxs-cloud:before {
  content: "\ecfd";
}
.bxs-cloud-download:before {
  content: "\ecfe";
}
.bxs-cloud-lightning:before {
  content: "\ecff";
}
.bxs-cloud-rain:before {
  content: "\ed00";
}
.bxs-cloud-upload:before {
  content: "\ed01";
}
.bxs-coffee:before {
  content: "\ed02";
}
.bxs-coffee-alt:before {
  content: "\ed03";
}
.bxs-coffee-togo:before {
  content: "\ed04";
}
.bxs-cog:before {
  content: "\ed05";
}
.bxs-coin:before {
  content: "\ed06";
}
.bxs-coin-stack:before {
  content: "\ed07";
}
.bxs-collection:before {
  content: "\ed08";
}
.bxs-color-fill:before {
  content: "\ed09";
}
.bxs-comment:before {
  content: "\ed0a";
}
.bxs-comment-add:before {
  content: "\ed0b";
}
.bxs-comment-check:before {
  content: "\ed0c";
}
.bxs-comment-detail:before {
  content: "\ed0d";
}
.bxs-comment-dots:before {
  content: "\ed0e";
}
.bxs-comment-edit:before {
  content: "\ed0f";
}
.bxs-comment-error:before {
  content: "\ed10";
}
.bxs-comment-minus:before {
  content: "\ed11";
}
.bxs-comment-x:before {
  content: "\ed12";
}
.bxs-compass:before {
  content: "\ed13";
}
.bxs-component:before {
  content: "\ed14";
}
.bxs-confused:before {
  content: "\ed15";
}
.bxs-contact:before {
  content: "\ed16";
}
.bxs-conversation:before {
  content: "\ed17";
}
.bxs-cookie:before {
  content: "\ed18";
}
.bxs-cool:before {
  content: "\ed19";
}
.bxs-copy:before {
  content: "\ed1a";
}
.bxs-copy-alt:before {
  content: "\ed1b";
}
.bxs-copyright:before {
  content: "\ed1c";
}
.bxs-coupon:before {
  content: "\ed1d";
}
.bxs-credit-card:before {
  content: "\ed1e";
}
.bxs-credit-card-alt:before {
  content: "\ed1f";
}
.bxs-credit-card-front:before {
  content: "\ed20";
}
.bxs-crop:before {
  content: "\ed21";
}
.bxs-crown:before {
  content: "\ed22";
}
.bxs-cube:before {
  content: "\ed23";
}
.bxs-cube-alt:before {
  content: "\ed24";
}
.bxs-cuboid:before {
  content: "\ed25";
}
.bxs-customize:before {
  content: "\ed26";
}
.bxs-cylinder:before {
  content: "\ed27";
}
.bxs-dashboard:before {
  content: "\ed28";
}
.bxs-data:before {
  content: "\ed29";
}
.bxs-detail:before {
  content: "\ed2a";
}
.bxs-devices:before {
  content: "\ed2b";
}
.bxs-diamond:before {
  content: "\ed2c";
}
.bxs-dice-1:before {
  content: "\ed2d";
}
.bxs-dice-2:before {
  content: "\ed2e";
}
.bxs-dice-3:before {
  content: "\ed2f";
}
.bxs-dice-4:before {
  content: "\ed30";
}
.bxs-dice-5:before {
  content: "\ed31";
}
.bxs-dice-6:before {
  content: "\ed32";
}
.bxs-direction-left:before {
  content: "\ed33";
}
.bxs-direction-right:before {
  content: "\ed34";
}
.bxs-directions:before {
  content: "\ed35";
}
.bxs-disc:before {
  content: "\ed36";
}
.bxs-discount:before {
  content: "\ed37";
}
.bxs-dish:before {
  content: "\ed38";
}
.bxs-dislike:before {
  content: "\ed39";
}
.bxs-dizzy:before {
  content: "\ed3a";
}
.bxs-dock-bottom:before {
  content: "\ed3b";
}
.bxs-dock-left:before {
  content: "\ed3c";
}
.bxs-dock-right:before {
  content: "\ed3d";
}
.bxs-dock-top:before {
  content: "\ed3e";
}
.bxs-dollar-circle:before {
  content: "\ed3f";
}
.bxs-donate-blood:before {
  content: "\ed40";
}
.bxs-donate-heart:before {
  content: "\ed41";
}
.bxs-door-open:before {
  content: "\ed42";
}
.bxs-doughnut-chart:before {
  content: "\ed43";
}
.bxs-down-arrow:before {
  content: "\ed44";
}
.bxs-down-arrow-alt:before {
  content: "\ed45";
}
.bxs-down-arrow-circle:before {
  content: "\ed46";
}
.bxs-down-arrow-square:before {
  content: "\ed47";
}
.bxs-download:before {
  content: "\ed48";
}
.bxs-downvote:before {
  content: "\ed49";
}
.bxs-drink:before {
  content: "\ed4a";
}
.bxs-droplet:before {
  content: "\ed4b";
}
.bxs-droplet-half:before {
  content: "\ed4c";
}
.bxs-dryer:before {
  content: "\ed4d";
}
.bxs-duplicate:before {
  content: "\ed4e";
}
.bxs-edit:before {
  content: "\ed4f";
}
.bxs-edit-alt:before {
  content: "\ed50";
}
.bxs-edit-location:before {
  content: "\ed51";
}
.bxs-eject:before {
  content: "\ed52";
}
.bxs-envelope:before {
  content: "\ed53";
}
.bxs-envelope-open:before {
  content: "\ed54";
}
.bxs-eraser:before {
  content: "\ed55";
}
.bxs-error:before {
  content: "\ed56";
}
.bxs-error-alt:before {
  content: "\ed57";
}
.bxs-error-circle:before {
  content: "\ed58";
}
.bxs-ev-station:before {
  content: "\ed59";
}
.bxs-exit:before {
  content: "\ed5a";
}
.bxs-extension:before {
  content: "\ed5b";
}
.bxs-eyedropper:before {
  content: "\ed5c";
}
.bxs-face:before {
  content: "\ed5d";
}
.bxs-factory:before {
  content: "\ed5e";
}
.bxs-fast-forward-circle:before {
  content: "\ed5f";
}
.bxs-file:before {
  content: "\ed60";
}
.bxs-file-archive:before {
  content: "\ed61";
}
.bxs-file-blank:before {
  content: "\ed62";
}
.bxs-file-css:before {
  content: "\ed63";
}
.bxs-file-doc:before {
  content: "\ed64";
}
.bxs-file-export:before {
  content: "\ed65";
}
.bxs-file-find:before {
  content: "\ed66";
}
.bxs-file-gif:before {
  content: "\ed67";
}
.bxs-file-html:before {
  content: "\ed68";
}
.bxs-file-image:before {
  content: "\ed69";
}
.bxs-file-import:before {
  content: "\ed6a";
}
.bxs-file-jpg:before {
  content: "\ed6b";
}
.bxs-file-js:before {
  content: "\ed6c";
}
.bxs-file-json:before {
  content: "\ed6d";
}
.bxs-file-md:before {
  content: "\ed6e";
}
.bxs-file-pdf:before {
  content: "\ed6f";
}
.bxs-file-plus:before {
  content: "\ed70";
}
.bxs-file-png:before {
  content: "\ed71";
}
.bxs-file-txt:before {
  content: "\ed72";
}
.bxs-film:before {
  content: "\ed73";
}
.bxs-filter-alt:before {
  content: "\ed74";
}
.bxs-first-aid:before {
  content: "\ed75";
}
.bxs-flag:before {
  content: "\ed76";
}
.bxs-flag-alt:before {
  content: "\ed77";
}
.bxs-flag-checkered:before {
  content: "\ed78";
}
.bxs-flame:before {
  content: "\ed79";
}
.bxs-flask:before {
  content: "\ed7a";
}
.bxs-florist:before {
  content: "\ed7b";
}
.bxs-folder:before {
  content: "\ed7c";
}
.bxs-folder-minus:before {
  content: "\ed7d";
}
.bxs-folder-open:before {
  content: "\ed7e";
}
.bxs-folder-plus:before {
  content: "\ed7f";
}
.bxs-food-menu:before {
  content: "\ed80";
}
.bxs-fridge:before {
  content: "\ed81";
}
.bxs-game:before {
  content: "\ed82";
}
.bxs-gas-pump:before {
  content: "\ed83";
}
.bxs-ghost:before {
  content: "\ed84";
}
.bxs-gift:before {
  content: "\ed85";
}
.bxs-graduation:before {
  content: "\ed86";
}
.bxs-grid:before {
  content: "\ed87";
}
.bxs-grid-alt:before {
  content: "\ed88";
}
.bxs-group:before {
  content: "\ed89";
}
.bxs-guitar-amp:before {
  content: "\ed8a";
}
.bxs-hand-down:before {
  content: "\ed8b";
}
.bxs-hand-left:before {
  content: "\ed8c";
}
.bxs-hand-right:before {
  content: "\ed8d";
}
.bxs-hand-up:before {
  content: "\ed8e";
}
.bxs-happy:before {
  content: "\ed8f";
}
.bxs-happy-alt:before {
  content: "\ed90";
}
.bxs-happy-beaming:before {
  content: "\ed91";
}
.bxs-happy-heart-eyes:before {
  content: "\ed92";
}
.bxs-hdd:before {
  content: "\ed93";
}
.bxs-heart:before {
  content: "\ed94";
}
.bxs-heart-circle:before {
  content: "\ed95";
}
.bxs-heart-square:before {
  content: "\ed96";
}
.bxs-help-circle:before {
  content: "\ed97";
}
.bxs-hide:before {
  content: "\ed98";
}
.bxs-home:before {
  content: "\ed99";
}
.bxs-home-circle:before {
  content: "\ed9a";
}
.bxs-home-heart:before {
  content: "\ed9b";
}
.bxs-home-smile:before {
  content: "\ed9c";
}
.bxs-hot:before {
  content: "\ed9d";
}
.bxs-hotel:before {
  content: "\ed9e";
}
.bxs-hourglass:before {
  content: "\ed9f";
}
.bxs-hourglass-bottom:before {
  content: "\eda0";
}
.bxs-hourglass-top:before {
  content: "\eda1";
}
.bxs-id-card:before {
  content: "\eda2";
}
.bxs-image:before {
  content: "\eda3";
}
.bxs-image-add:before {
  content: "\eda4";
}
.bxs-image-alt:before {
  content: "\eda5";
}
.bxs-inbox:before {
  content: "\eda6";
}
.bxs-info-circle:before {
  content: "\eda7";
}
.bxs-info-square:before {
  content: "\eda8";
}
.bxs-institution:before {
  content: "\eda9";
}
.bxs-joystick:before {
  content: "\edaa";
}
.bxs-joystick-alt:before {
  content: "\edab";
}
.bxs-joystick-button:before {
  content: "\edac";
}
.bxs-key:before {
  content: "\edad";
}
.bxs-keyboard:before {
  content: "\edae";
}
.bxs-label:before {
  content: "\edaf";
}
.bxs-landmark:before {
  content: "\edb0";
}
.bxs-landscape:before {
  content: "\edb1";
}
.bxs-laugh:before {
  content: "\edb2";
}
.bxs-layer:before {
  content: "\edb3";
}
.bxs-layer-minus:before {
  content: "\edb4";
}
.bxs-layer-plus:before {
  content: "\edb5";
}
.bxs-layout:before {
  content: "\edb6";
}
.bxs-left-arrow:before {
  content: "\edb7";
}
.bxs-left-arrow-alt:before {
  content: "\edb8";
}
.bxs-left-arrow-circle:before {
  content: "\edb9";
}
.bxs-left-arrow-square:before {
  content: "\edba";
}
.bxs-left-down-arrow-circle:before {
  content: "\edbb";
}
.bxs-left-top-arrow-circle:before {
  content: "\edbc";
}
.bxs-like:before {
  content: "\edbd";
}
.bxs-location-plus:before {
  content: "\edbe";
}
.bxs-lock:before {
  content: "\edbf";
}
.bxs-lock-alt:before {
  content: "\edc0";
}
.bxs-lock-open:before {
  content: "\edc1";
}
.bxs-lock-open-alt:before {
  content: "\edc2";
}
.bxs-log-in:before {
  content: "\edc3";
}
.bxs-log-in-circle:before {
  content: "\edc4";
}
.bxs-log-out:before {
  content: "\edc5";
}
.bxs-log-out-circle:before {
  content: "\edc6";
}
.bxs-low-vision:before {
  content: "\edc7";
}
.bxs-magic-wand:before {
  content: "\edc8";
}
.bxs-magnet:before {
  content: "\edc9";
}
.bxs-map:before {
  content: "\edca";
}
.bxs-map-alt:before {
  content: "\edcb";
}
.bxs-map-pin:before {
  content: "\edcc";
}
.bxs-mask:before {
  content: "\edcd";
}
.bxs-medal:before {
  content: "\edce";
}
.bxs-megaphone:before {
  content: "\edcf";
}
.bxs-meh:before {
  content: "\edd0";
}
.bxs-meh-alt:before {
  content: "\edd1";
}
.bxs-meh-blank:before {
  content: "\edd2";
}
.bxs-memory-card:before {
  content: "\edd3";
}
.bxs-message:before {
  content: "\edd4";
}
.bxs-message-add:before {
  content: "\edd5";
}
.bxs-message-alt:before {
  content: "\edd6";
}
.bxs-message-alt-add:before {
  content: "\edd7";
}
.bxs-message-alt-check:before {
  content: "\edd8";
}
.bxs-message-alt-detail:before {
  content: "\edd9";
}
.bxs-message-alt-dots:before {
  content: "\edda";
}
.bxs-message-alt-edit:before {
  content: "\eddb";
}
.bxs-message-alt-error:before {
  content: "\eddc";
}
.bxs-message-alt-minus:before {
  content: "\eddd";
}
.bxs-message-alt-x:before {
  content: "\edde";
}
.bxs-message-check:before {
  content: "\eddf";
}
.bxs-message-detail:before {
  content: "\ede0";
}
.bxs-message-dots:before {
  content: "\ede1";
}
.bxs-message-edit:before {
  content: "\ede2";
}
.bxs-message-error:before {
  content: "\ede3";
}
.bxs-message-minus:before {
  content: "\ede4";
}
.bxs-message-rounded:before {
  content: "\ede5";
}
.bxs-message-rounded-add:before {
  content: "\ede6";
}
.bxs-message-rounded-check:before {
  content: "\ede7";
}
.bxs-message-rounded-detail:before {
  content: "\ede8";
}
.bxs-message-rounded-dots:before {
  content: "\ede9";
}
.bxs-message-rounded-edit:before {
  content: "\edea";
}
.bxs-message-rounded-error:before {
  content: "\edeb";
}
.bxs-message-rounded-minus:before {
  content: "\edec";
}
.bxs-message-rounded-x:before {
  content: "\eded";
}
.bxs-message-square:before {
  content: "\edee";
}
.bxs-message-square-add:before {
  content: "\edef";
}
.bxs-message-square-check:before {
  content: "\edf0";
}
.bxs-message-square-detail:before {
  content: "\edf1";
}
.bxs-message-square-dots:before {
  content: "\edf2";
}
.bxs-message-square-edit:before {
  content: "\edf3";
}
.bxs-message-square-error:before {
  content: "\edf4";
}
.bxs-message-square-minus:before {
  content: "\edf5";
}
.bxs-message-square-x:before {
  content: "\edf6";
}
.bxs-message-x:before {
  content: "\edf7";
}
.bxs-meteor:before {
  content: "\edf8";
}
.bxs-microchip:before {
  content: "\edf9";
}
.bxs-microphone:before {
  content: "\edfa";
}
.bxs-microphone-alt:before {
  content: "\edfb";
}
.bxs-microphone-off:before {
  content: "\edfc";
}
.bxs-minus-circle:before {
  content: "\edfd";
}
.bxs-minus-square:before {
  content: "\edfe";
}
.bxs-mobile:before {
  content: "\edff";
}
.bxs-mobile-vibration:before {
  content: "\ee00";
}
.bxs-moon:before {
  content: "\ee01";
}
.bxs-mouse:before {
  content: "\ee02";
}
.bxs-mouse-alt:before {
  content: "\ee03";
}
.bxs-movie:before {
  content: "\ee04";
}
.bxs-music:before {
  content: "\ee05";
}
.bxs-navigation:before {
  content: "\ee06";
}
.bxs-network-chart:before {
  content: "\ee07";
}
.bxs-news:before {
  content: "\ee08";
}
.bxs-no-entry:before {
  content: "\ee09";
}
.bxs-note:before {
  content: "\ee0a";
}
.bxs-notepad:before {
  content: "\ee0b";
}
.bxs-notification:before {
  content: "\ee0c";
}
.bxs-notification-off:before {
  content: "\ee0d";
}
.bxs-offer:before {
  content: "\ee0e";
}
.bxs-package:before {
  content: "\ee0f";
}
.bxs-paint:before {
  content: "\ee10";
}
.bxs-paint-roll:before {
  content: "\ee11";
}
.bxs-palette:before {
  content: "\ee12";
}
.bxs-paper-plane:before {
  content: "\ee13";
}
.bxs-parking:before {
  content: "\ee14";
}
.bxs-paste:before {
  content: "\ee15";
}
.bxs-pen:before {
  content: "\ee16";
}
.bxs-pencil:before {
  content: "\ee17";
}
.bxs-phone:before {
  content: "\ee18";
}
.bxs-phone-call:before {
  content: "\ee19";
}
.bxs-phone-incoming:before {
  content: "\ee1a";
}
.bxs-phone-outgoing:before {
  content: "\ee1b";
}
.bxs-photo-album:before {
  content: "\ee1c";
}
.bxs-piano:before {
  content: "\ee1d";
}
.bxs-pie-chart:before {
  content: "\ee1e";
}
.bxs-pie-chart-alt:before {
  content: "\ee1f";
}
.bxs-pie-chart-alt-2:before {
  content: "\ee20";
}
.bxs-pin:before {
  content: "\ee21";
}
.bxs-pizza:before {
  content: "\ee22";
}
.bxs-plane:before {
  content: "\ee23";
}
.bxs-plane-alt:before {
  content: "\ee24";
}
.bxs-plane-land:before {
  content: "\ee25";
}
.bxs-planet:before {
  content: "\ee26";
}
.bxs-plane-take-off:before {
  content: "\ee27";
}
.bxs-playlist:before {
  content: "\ee28";
}
.bxs-plug:before {
  content: "\ee29";
}
.bxs-plus-circle:before {
  content: "\ee2a";
}
.bxs-plus-square:before {
  content: "\ee2b";
}
.bxs-pointer:before {
  content: "\ee2c";
}
.bxs-polygon:before {
  content: "\ee2d";
}
.bxs-printer:before {
  content: "\ee2e";
}
.bxs-purchase-tag:before {
  content: "\ee2f";
}
.bxs-purchase-tag-alt:before {
  content: "\ee30";
}
.bxs-pyramid:before {
  content: "\ee31";
}
.bxs-quote-alt-left:before {
  content: "\ee32";
}
.bxs-quote-alt-right:before {
  content: "\ee33";
}
.bxs-quote-left:before {
  content: "\ee34";
}
.bxs-quote-right:before {
  content: "\ee35";
}
.bxs-quote-single-left:before {
  content: "\ee36";
}
.bxs-quote-single-right:before {
  content: "\ee37";
}
.bxs-radiation:before {
  content: "\ee38";
}
.bxs-radio:before {
  content: "\ee39";
}
.bxs-receipt:before {
  content: "\ee3a";
}
.bxs-rectangle:before {
  content: "\ee3b";
}
.bxs-rename:before {
  content: "\ee3c";
}
.bxs-report:before {
  content: "\ee3d";
}
.bxs-rewind-circle:before {
  content: "\ee3e";
}
.bxs-right-arrow:before {
  content: "\ee3f";
}
.bxs-right-arrow-alt:before {
  content: "\ee40";
}
.bxs-right-arrow-circle:before {
  content: "\ee41";
}
.bxs-right-arrow-square:before {
  content: "\ee42";
}
.bxs-right-down-arrow-circle:before {
  content: "\ee43";
}
.bxs-right-top-arrow-circle:before {
  content: "\ee44";
}
.bxs-rocket:before {
  content: "\ee45";
}
.bxs-ruler:before {
  content: "\ee46";
}
.bxs-sad:before {
  content: "\ee47";
}
.bxs-save:before {
  content: "\ee48";
}
.bxs-school:before {
  content: "\ee49";
}
.bxs-search:before {
  content: "\ee4a";
}
.bxs-search-alt-2:before {
  content: "\ee4b";
}
.bxs-select-multiple:before {
  content: "\ee4c";
}
.bxs-send:before {
  content: "\ee4d";
}
.bxs-server:before {
  content: "\ee4e";
}
.bxs-shapes:before {
  content: "\ee4f";
}
.bxs-share:before {
  content: "\ee50";
}
.bxs-share-alt:before {
  content: "\ee51";
}
.bxs-shield:before {
  content: "\ee52";
}
.bxs-shield-alt-2:before {
  content: "\ee53";
}
.bxs-shield-x:before {
  content: "\ee54";
}
.bxs-ship:before {
  content: "\ee55";
}
.bxs-shocked:before {
  content: "\ee56";
}
.bxs-shopping-bag:before {
  content: "\ee57";
}
.bxs-shopping-bag-alt:before {
  content: "\ee58";
}
.bxs-shopping-bags:before {
  content: "\ee59";
}
.bxs-show:before {
  content: "\ee5a";
}
.bxs-skip-next-circle:before {
  content: "\ee5b";
}
.bxs-skip-previous-circle:before {
  content: "\ee5c";
}
.bxs-skull:before {
  content: "\ee5d";
}
.bxs-sleepy:before {
  content: "\ee5e";
}
.bxs-slideshow:before {
  content: "\ee5f";
}
.bxs-smile:before {
  content: "\ee60";
}
.bxs-sort-alt:before {
  content: "\ee61";
}
.bxs-spa:before {
  content: "\ee62";
}
.bxs-spray-can:before {
  content: "\ee63";
}
.bxs-spreadsheet:before {
  content: "\ee64";
}
.bxs-square:before {
  content: "\ee65";
}
.bxs-square-rounded:before {
  content: "\ee66";
}
.bxs-star:before {
  content: "\ee67";
}
.bxs-star-half:before {
  content: "\ee68";
}
.bxs-sticker:before {
  content: "\ee69";
}
.bxs-stopwatch:before {
  content: "\ee6a";
}
.bxs-store:before {
  content: "\ee6b";
}
.bxs-store-alt:before {
  content: "\ee6c";
}
.bxs-sun:before {
  content: "\ee6d";
}
.bxs-tachometer:before {
  content: "\ee6e";
}
.bxs-tag:before {
  content: "\ee6f";
}
.bxs-tag-x:before {
  content: "\ee70";
}
.bxs-taxi:before {
  content: "\ee71";
}
.bxs-tennis-ball:before {
  content: "\ee72";
}
.bxs-terminal:before {
  content: "\ee73";
}
.bxs-thermometer:before {
  content: "\ee74";
}
.bxs-time:before {
  content: "\ee75";
}
.bxs-time-five:before {
  content: "\ee76";
}
.bxs-timer:before {
  content: "\ee77";
}
.bxs-tired:before {
  content: "\ee78";
}
.bxs-toggle-left:before {
  content: "\ee79";
}
.bxs-toggle-right:before {
  content: "\ee7a";
}
.bxs-tone:before {
  content: "\ee7b";
}
.bxs-torch:before {
  content: "\ee7c";
}
.bxs-to-top:before {
  content: "\ee7d";
}
.bxs-traffic:before {
  content: "\ee7e";
}
.bxs-traffic-barrier:before {
  content: "\ee7f";
}
.bxs-traffic-cone:before {
  content: "\ee80";
}
.bxs-train:before {
  content: "\ee81";
}
.bxs-trash:before {
  content: "\ee82";
}
.bxs-trash-alt:before {
  content: "\ee83";
}
.bxs-tree:before {
  content: "\ee84";
}
.bxs-trophy:before {
  content: "\ee85";
}
.bxs-truck:before {
  content: "\ee86";
}
.bxs-t-shirt:before {
  content: "\ee87";
}
.bxs-up-arrow:before {
  content: "\ee88";
}
.bxs-up-arrow-alt:before {
  content: "\ee89";
}
.bxs-up-arrow-circle:before {
  content: "\ee8a";
}
.bxs-up-arrow-square:before {
  content: "\ee8b";
}
.bxs-upside-down:before {
  content: "\ee8c";
}
.bxs-upvote:before {
  content: "\ee8d";
}
.bxs-user:before {
  content: "\ee8e";
}
.bxs-user-badge:before {
  content: "\ee8f";
}
.bxs-user-check:before {
  content: "\ee90";
}
.bxs-user-circle:before {
  content: "\ee91";
}
.bxs-user-detail:before {
  content: "\ee92";
}
.bxs-user-minus:before {
  content: "\ee93";
}
.bxs-user-pin:before {
  content: "\ee94";
}
.bxs-user-plus:before {
  content: "\ee95";
}
.bxs-user-rectangle:before {
  content: "\ee96";
}
.bxs-user-voice:before {
  content: "\ee97";
}
.bxs-user-x:before {
  content: "\ee98";
}
.bxs-vector:before {
  content: "\ee99";
}
.bxs-vial:before {
  content: "\ee9a";
}
.bxs-video:before {
  content: "\ee9b";
}
.bxs-video-off:before {
  content: "\ee9c";
}
.bxs-video-plus:before {
  content: "\ee9d";
}
.bxs-video-recording:before {
  content: "\ee9e";
}
.bxs-videos:before {
  content: "\ee9f";
}
.bxs-volume:before {
  content: "\eea0";
}
.bxs-volume-full:before {
  content: "\eea1";
}
.bxs-volume-low:before {
  content: "\eea2";
}
.bxs-volume-mute:before {
  content: "\eea3";
}
.bxs-wallet:before {
  content: "\eea4";
}
.bxs-wallet-alt:before {
  content: "\eea5";
}
.bxs-washer:before {
  content: "\eea6";
}
.bxs-watch:before {
  content: "\eea7";
}
.bxs-watch-alt:before {
  content: "\eea8";
}
.bxs-webcam:before {
  content: "\eea9";
}
.bxs-widget:before {
  content: "\eeaa";
}
.bxs-window-alt:before {
  content: "\eeab";
}
.bxs-wine:before {
  content: "\eeac";
}
.bxs-wink-smile:before {
  content: "\eead";
}
.bxs-wink-tongue:before {
  content: "\eeae";
}
.bxs-wrench:before {
  content: "\eeaf";
}
.bxs-x-circle:before {
  content: "\eeb0";
}
.bxs-x-square:before {
  content: "\eeb1";
}
.bxs-yin-yang:before {
  content: "\eeb2";
}
.bxs-zap:before {
  content: "\eeb3";
}
.bxs-zoom-in:before {
  content: "\eeb4";
}
.bxs-zoom-out:before {
  content: "\eeb5";
}
